@media only screen and (max-width: 1200px) {
  #tab-frauen-bundesliga { display: none !important; }
}

@media only screen and (max-width: 1100px) {
  #tab-conference-league { display: none !important; }
}

@media only screen and (max-width: 1000px) {
  #tab-europa-league { display: none !important; }
}

@media only screen and (max-width: 900px) {
  #tab-premier-league { display: none !important; }
}

@media only screen and (max-width: 800px) {
  #tab-3-liga { display: none !important; }
}

@media only screen and (max-width: 700px) {
  #tab-2-bundesliga { display: none !important; }
}

@media only screen and (max-width: 600px) {
  #tab-dfb-pokal { display: none !important; }
}
