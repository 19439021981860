@media only screen and (max-width: 319px) {
  .remove319 { display: none !important; }
}

@media only screen and (max-width: 359px) {
  .remove359 { display: none !important; }
}

@media only screen and (max-width: 374px) {
  .remove374 { display: none !important; }
}

@media only screen and (max-width: 389px) {                         /* iPhone 15 */
  .remove389 { display: none !important; }
}
