.safe-area {
  padding-left: 16px;                                               /* iOS 13: Safe Area */
  padding-right: 16px;                                              /* iOS 13: Safe Area */
}

@media (min-width: 640px) {
  .safe-area {
    padding-left: 20px;                                             /* iOS 13: Safe Area iPad */
    padding-right: 20px;                                            /* iOS 13: Safe Area iPad */
  }
}

@media (min-width: 1280px) {
  .safe-area {
    padding-left: calc(50% - 624px);                                /* 624 = 1280/2-16 */
    padding-right: calc(50% - 624px);
  }
}

@media (min-width: 1280px) {                                        /* Desktop-only version */
  .safe-area-desktop {
    padding-left: calc(50% - 624px);                                /* 640 = 1280/2-16 */
    padding-right: calc(50% - 624px);
  }
}

@media (min-width: 768px) {                                        /* Desktop-only version */
  .safe-area-desktop-small {
    padding-left: calc(50% - 384px);                                /* 368 = 768/2 */
    padding-right: calc(50% - 384px);
  }
}
