/**
 * <select class="ns-select">
 */

 .ns-select {
  appearance: none;                                                 /* Remove default styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;                                                      /* flex-grow didn't work */
  height: 44px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;                                                  /* On hover, show finger instead of normal cursor */
  /* Text */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;                                               /* Move right: 12px (size of icon) + 8px */
  /* Icon */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='6'%3E%3Cpolygon points='0,0 12,0 6,6' /%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: left center;
}

.ns-select:focus {
  outline: none;
}

.ns-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
