/* Reset anchor */

a {
  text-decoration: none;                                            /* Remove underline */
  color: inherit;                                                   /* Remove blue color */
}





/* Reset button */

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  text-align: inherit;
  outline: none;
  border-radius: 0;
  -webkit-font-smoothing: inherit;
  -webkit-user-select: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;                         /* Safari stop blinking */
  -moz-osx-font-smoothing: inherit;
  -moz-user-select: none;
  -ms-user-select: none;
}

button::-moz-focus-inner {                                          /* Remove excess padding and border in Firefox 4+ */
  border: 0;
  padding: 0;
}
